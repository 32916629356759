* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Nova;
  src: url(./assets/NovaFlat-Regular.ttf);
}

body {
  color: #fff;
  font-family: "Nova", sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-image: url("./assets/ingBg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#root,
.App {
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-width: 0;

  max-width: 100vw;
}

a {
  text-decoration: none;
}

/* width */
/* ::-webkit-scrollbar {
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: transparent;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  background: #767bfa;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
} */

.contentWrapper {
  flex-basis: 100%;
  flex-grow: 1;
  padding-top: 1.5625rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  min-height: 0;
  overflow-y: auto;
}

.flex {
  display: flex;
}
.al-ce {
  align-items: center;
}
.jc-sp {
  justify-content: space-between;
}
.jc-ce {
  justify-content: center;
}

.jc-fe {
  justify-content: flex-end;
}

.jc-fs {
  justify-content: flex-start;
}

.green {
  color: #8ff093;
}

.loader {
  width: 1.75rem;
  height: 1.75rem;
  border: 0.1875rem solid #767bfa;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotation 0.5s linear infinite;
  -webkit-animation: rotation 0.5s linear infinite;
}

/* .modal .MuiBackdrop-root.css-sox5kk-MuiBackdrop-root {
  background: rgba(67, 53, 97, 0.22);
  backdrop-filter: blur(0.375rem);
} */

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
