.box {
  padding: min(39px, 8vw);
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  width: 100%;
  max-width: 428px;
  padding-bottom: 10px;
  margin: auto;
  padding-bottom: 1rem;
}

.imagesBox {
  display: flex;
  align-items: center;
  min-width: 0;
  overflow-x: auto;
  background-image: url("../../assets/bg2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: top;
  margin-top: -1rem;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 0;
  aspect-ratio: 350/240;
  margin-bottom: 1.5rem;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: #482b73;
  backdrop-filter: blur(0.125rem);
}

.loader img {
  height: 6rem;
}

.shoeDetails {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  padding-left: 1rem;
}

.shoeDetails > div:first-child {
  margin-bottom: 0.3125rem;
}

.imageBox {
  /* background: #2b273f; */
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  height: 8.25rem;
  flex-basis: 8.25rem;
  flex-shrink: 0;
  flex-grow: 0;
  margin-bottom: 0.5rem;
  max-width: 8.825rem;
  object-fit: cover;
}

.imageBox:not(:last-child) {
  margin-right: 0.9375rem;
}

.imagesBox::-webkit-scrollbar {
  height: 0.3125rem;
}

.ship .btn {
  margin-top: 5rem;
}

.green {
  color: #8ff093;
}

.red {
  color: #fa7676;
}

.shoeImgContainer {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  align-items: center;
  flex-direction: column;
  background-image: url("../../assets/shoeBg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
  aspect-ratio: 350/240;
}

.shoeImgContainer > img {
  object-fit: contain;
}

.wrapper {
  filter: drop-shadow(0px 20px 30px rgba(16, 20, 28, 0.6));
  -webkit-filter: drop-shadow(0px 20px 30px rgba(16, 20, 28, 0.6));
}

.shoeImgContainer > div:first-child {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.shoeDetails {
  padding-bottom: 1.75rem;
}

.shoeDetails > div:last-child {
  max-width: 24ch;
}

.shoeImg {
  height: 5.625rem;
  margin: 1rem 0;
  border-radius: 5px;
  margin-bottom: 0rem;
}

.value > div:last-child {
  text-align: right;
}

.prevContainer {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 3.7rem;
}

.prevImg {
  height: 24rem;
  width: 24rem;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0);
  object-fit: cover;
}

.border {
  max-width: 18.4375rem;
  border-top: 0.125rem solid #332f47;
  margin: auto;
  margin-bottom: 1.25rem;
}

.mb {
  margin-top: 2.25rem;
}

.thank {
  margin-bottom: 1.875rem;
  text-align: center;
  font-weight: 500;
  font-size: 1.4375rem;
  line-height: 1.75rem;
  margin-top: 1.5625rem;
}

.verified {
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.8125rem;
  text-align: center;
  margin-bottom: 1.9375rem;
}

.twoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  margin-top: 1.25rem;
}

.gridItemHeader {
  padding-left: 1.375rem;
  margin-bottom: 0.625rem;
}

.gridItemContentContainer {
  border: 0.125rem solid #353c53;
  box-shadow: inset 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.5rem 1.25rem;
  overflow: hidden;
}

.upload {
  display: flex;
  font-weight: 300;
}

.upload div {
  text-decoration: underline;
  cursor: pointer;
}

.upload span {
  margin-right: 0.0625rem;
}

.close {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.gridItemContent textarea {
  resize: none;
  height: 100%;
  background-color: transparent;
  font-family: inherit;
  border: none;
  outline: none;
  color: #f6f7ff;
  width: 100%;
}

::placeholder {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #5a5d74;
}

.column {
  display: flex;
  flex-direction: column;
}

.column .gridItem:last-child {
  margin-top: 1.25rem;
  height: 100%;
}

.column .gridItem {
  display: flex;
  flex-direction: column;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 16ch;
}

.notesText {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  max-width: 90%;
  color: #f6f7ff;
  margin-top: 1.875rem;
}

.notesText a {
  color: #767bfa;
}

.barcode {
  display: flex;
  align-items: center;
  max-width: 17.5rem;
  margin: auto;
  margin-top: 1.25rem;
  width: 100%;
  justify-content: center;
}

.value {
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-bottom: 0.9375rem;
}

.bottom span {
  margin: 0 0.5rem;
}

@media (max-width: 600px) {
  .verified {
    font-size: 6.25vw;
  }
}

.arrowLeft,
.arrowRight {
  position: relative;
  transform: scale(0.7);
  padding: 5px;
  margin: 30px auto;
  background: #000;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: all 0.2s linear;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  z-index: 12;
}

.arrowRight:active {
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
}

.arrowLeft:active {
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
}

.arrowLeft::after,
.arrowRight::after {
  content: "";
  position: absolute;
  left: 14px;
  z-index: 11;
  display: block;
  width: 25px;
  height: 25px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}
.arrowLeft::after {
  top: 14px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.arrowRight::after {
  top: 10px;
  transform: rotate(130deg);
  -webkit-transform: rotate(130deg);
  -moz-transform: rotate(130deg);
  -ms-transform: rotate(130deg);
  -o-transform: rotate(130deg);
}
