.btn {
    padding: 0.625rem 0.875rem;
    background: #2f2b43;
    box-shadow: 0rem 0rem 0.25rem rgba(0, 0, 0, 0.15);
    border-radius: 0.3125rem;
    display: flex;
    align-items: center;
    color: #5a5d74;
    cursor: pointer;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
  }
  
  .btn:hover {
    box-shadow: 0rem 0rem 0.75rem rgba(0, 0, 0, 0.25);
  }
  
  .btn img,
  .btn svg {
    height: 1.4375rem;
    margin-right: 0.625rem;
  }
  