.light {
  color: #f6f7ff;
}

.dark {
  color: rgba(246, 247, 255, 0.55);
}

.header {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #767bfa;
}

.whiteHeader {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #f6f7ff;
}

.headerText {
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
  color: #f6f7ff;
}

.green {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #8ff093;
}

.smText {
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0.9375rem;
  color: #5a5d74;
}

.smTextBlue {
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 1.25rem;
}

.small {
  font-size: 0.875rem;
}
